import logo from './logo.svg';
import './App.css';



function App() {
  return (
    <div className={"container"}>
      <div className="card">
        <img className={"logoimg"} src={"https://onlypa.ws/share/2021/10/Honeyview_lunar%20reg_final.png"}/>
        <h1 className={"header"}>noku.pw</h1>
        <span className={"linky"}>
          |<a href={"https://madeinabyss.net"}>madeinabyss.net</a> |
          <a href={"https://onlypa.ws"}>onlypa.ws</a> |
          <a href={"https://twitter.com/nokusutwo"}>twitter</a> |
          <a href={"https://github.com/nokusukun"}>github</a>
        </span>
      </div>
    </div>
  );
}

export default App;
